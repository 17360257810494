<template>
	<div >
		<img src="../../../assets/imges/index/29.png" class="headerimg" />
		<div class="ExhibitionDetails">
			<div class="header">
				<span class="title1" @click="todynamic()">集团动态</span><span class="arrows">></span>
				<span class="title2" @click="todynamic(info.dynamicTypeId)">{{info.dynamicTypeName}}</span><span class="arrows">></span>
				<span class="title2" style="color: #2F7FFC;">{{info.title}}</span>
			</div>
			<div class="Exhibitionheader">
				<div class="tltle">{{info.title}}</div>
				<div class="time">{{info.releaseTime}}</div>
			</div>
			<div v-html="info.content"  class="ck-content el-form-item__content">

			</div>
			<div class="ExhibitionDetailsbottom">
				<div style="display: inline-block;" @click="refresh(info.lastId)" v-if="info.lastId != 0">
					<img src="../../../assets/public/prev.png" />
					<span>{{info.lastName}}</span>
				</div>
				<div style="float: right;display: inline-block;cursor: pointer;" @click="refresh(info.nextId)" v-if="info.nextId != 0">
					<span>{{info.nextName}}</span>
					<img src="../../../assets/public/next.png" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		GroupDynamicFrontendDetail
	} from '../../../api/index.js'
	import {
		currenttime
	} from '../../../utils/util.js'
	import '../../../utils/content-styles.css'
	export default {
		data() {
			return {
				info: {},
			}
		},
		created(){
			this.getinfo()
		},
		methods: {
			todynamic(id){
				if(id){
					this.$router.push({
						path: 'dynamic',
						query: {
							id: id
						}
					})
				} else {
					this.$router.push('dynamic')
				}
				
			},
			getinfo() {
				GroupDynamicFrontendDetail({
					id: this.$route.query.id
				}).then(res => {
					this.info = res.data
					document.title =this.info.title+"-邦伲德集团官网"
					this.info.releaseTime = currenttime(new Date(this.info.releaseTime))
				})
			},
			refresh(id) {
				this.$router.push({
					path: 'ExhibitionDetails',
					query: {
						id: id
					}
				})
				this.getinfo()
			}
		}
	}
</script>

<style scoped lang="less">
.el-form-item__content {
    line-height: 40px;
    position: relative;
    font-size: 14px;
}
	.ExhibitionDetails {
		width: 80%;
		margin: 0 auto;

		.header {
			padding: 59px 0;
			font-size: 28px;
			border-bottom: 1px solid #979797;

			.title1 {
				font-weight: bold;
				cursor: pointer;
			}

			.arrows {
				color: #BEBEBE;
				padding: 0 25px;
			}

			.title2 {
				font-weight: 500;
				color: #B4B4B4;
				cursor: pointer;
			}

			.title2hover {
				color: #2F7FFC;
				padding-bottom: 15px;
				border-bottom: 6px solid #2F7FFC;

			}

		}

		.Exhibitionheader {
			text-align: center;
			margin-top: 113px;
			margin-bottom: 64px;
			.tltle {
				font-size: 30px;
				font-weight: 500;
				line-height: 26px;
			}

			.time {
				font-size: 18px;
				font-weight: bold;
				color: #D2D2D2;
				line-height: 18px;
				margin-top: 25px;
			}
		}

		.ExhibitionDetailsbottom {
			padding: 43px 0;
			border-top: 1px solid #E6E6E6;
			border-bottom: 1px solid #E6E6E6;
			margin-top: 113px;

			img {
				width: 35px;
				height: 32px;
				vertical-align: middle;
			}

			span {
				vertical-align: middle;
				font-size: 16px;
				font-weight: 500;
				color: #A1A1A1;
				line-height: 22px;
				margin: 32px;
			}
		}
	}
</style>
