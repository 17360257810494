const formatTime = date => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()

  return [year, month, day].map(formatNumber).join('-') + ' ' + [hour, minute, second].map(formatNumber).join(':')
}

const currenttime = date => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const minute = date.getMinutes()

  return year+'年'+month+'月'+day+'日 '+ [hour, minute].map(formatNumber).join(':')
}

const time = date => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const minute = date.getMinutes()

  return [year, month, day].map(formatNumber).join('-')
}

const daytim = date => {
  const day = date.getDate()
  return day
}

// 返回年月
const yearmonth = date => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  return [year, month].map(formatNumber).join('-')
}


const monthtim = date => {
  const month = date.getMonth() + 1
  return month
}

const formatNumber = n => {
  n = n.toString()
  return n[1] ? n : '0' + n
}

/**
 * @method 根据日期判断今天周几
 * @param {*} date 日期格式为字符串2018-01-01
 */
function getDateWeek (date) {
  const weekDay = ['日', '周一', '周二', '周三', '周四', '周五', '六']
  const myDate = new Date(Date.parse(date))
  return weekDay[myDate.getDay()]
}


// 获取上个月份的最后一天
const getmonthend = date => {
 const year = date.getFullYear()
 const month = date.getMonth()
 const day = new Date(year, month, 0).getDate()
 const time = [year, month, day].map(formatNumber).join('-')
  return Date.parse(new Date(time)) / 1000
}

module.exports = {
  formatTime: formatTime,
  currenttime,
  time,
  getDateWeek,
  daytim,
  getmonthend,
  monthtim,
  yearmonth
}
